import React from "react";
import styled from "styled-components";

import {
  Container,
  Row,
  Col,
  Button,
  Image,
  Card,
  CardGroup,
} from "react-bootstrap";

import FrontHeader from "../components/front-header";
import FrontFooter from "../components/front-footer";
import GlobalStyle from "../components/global";
import PostSEO from "../components/post-seo";

// Static imports
// TODO: use query for image list
import screen1 from "../images/screenshots/Dive03.jpg";
import btnItch from "../images/BtnItch1.png";
import btnSteam from "../images/BtnSteam2.png";
import btnSwitch from "../images/eshop-light.png";

import { navigate } from "@reach/router";

const imageObj = {
  src: screen1,
  width: "300",
  height: "200",
};

/*
const callBox = ({ headText, btnText, linkUrl }) => (
  <Featured>
    <h3 className="section-heading con-font">{headText}</h3>
    <p>
      <Button variant="info" size="lg" block href={linkUrl} target="_blank">
        {btnText.toUpperCase()}
      </Button>
    </p>
  </Featured>
); */


const ReviewBox = ({ title, desctext, linkurl }) => (
  <RevCard>
    <Card.Body>
      <a
        href={linkurl}
        target= "_blank" 
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        <Card.Title>{title}</Card.Title>
      </a>

      <Card.Subtitle>{desctext}</Card.Subtitle>
    </Card.Body>
  </RevCard>
);


class IndexPage extends React.Component {
  render() {
    return (
      <>
        <FrontHeader />
        <PostSEO
          title="Dune Sea"
          description="The original Canada Goose Game"
          image={imageObj}
        />

        <GradBack>
          <div style={{ textAlign: "center", margin: 0 }}>
            <iframe
              title="trailer"
              description="Dune Sea Switch Trailer"
              src="https://player.vimeo.com/video/432648452"
              width="90%"
              height="564"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            />
          </div>

          <Container>
            <Row className="justify-content-md-center sep1">
              <Col lg={2}>
                <a href="https://www.nintendo.com/games/detail/dune-sea-switch/">
                  <img src={btnSwitch} alt="Switch" />
                </a>
              </Col>
              <Col lg={{ span: 3, offset: 0 }}>
                <HalfSep />
                <a href="https://store.steampowered.com/app/1103120/Dune_Sea/">
                  <img src={btnSteam} alt="Steam" />
                </a>
              </Col>
              <Col lg={{ span: 3, offset: 0 }}>
                <HalfSep />
                <a href="https://froliclabs.itch.io/dune-sea">
                  <img src={btnItch} alt="Itch" />
                </a>
              </Col>
            </Row>

            <Separator />
            

            <Row className="justify-content-md-center">
              <Col lg={{ span: 6, offset: 0 }}>
                <iframe
                  title="bandcamp"
                  style={{ border: 0, width: "100%", height: "120px" }}
                  src="https://bandcamp.com/EmbeddedPlayer/album=1793503424/size=large/bgcol=333333/linkcol=0f91ff/tracklist=false/artwork=small/transparent=true/"
                  seamless
                >
                  <a href="https://jakebutineau.bandcamp.com/album/dune-sea-original-game-soundtrack">
                    Dune Sea (Original Game Soundtrack) by Jake Butineau
                  </a>
                </iframe>
              </Col>
            </Row>
          </Container>

          <Container style={SecStyle}>
            <Row className="justify-content-md-center">
              <Col lg={{ span: 2 }}>
                <Button
                  variant="info"
                  size="md"
                  block
                  onClick={() => navigate("/notes")}
                >
                  {"Release Notes".toUpperCase()}
                </Button>
              </Col>
            </Row>
          </Container>
          <Separator />
        </GradBack>
        <GlobalStyle />

        <FrontFooter />
      </>
    );
  }
}

const SecStyle = {
  padding: "20px",
};

const SectionWrap = styled.div`
  padding: 20px 0;
  border-top: 0px solid #e70000;
  border-bottom: 0px;
  background-color: #f9f8de;
`;

/*
const DiscordCont = styled.div`
  background: url(${discordImg}) repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
`; */

const GradBack = styled(SectionWrap)`
  background: #fbf9bc;
  background: -webkit-linear-gradient(#a36a1d, #f9f8de);
  background: -o-linear-gradient(#a36a1d, #f9f8de);
  background: -moz-linear-gradient(#a36a1d, #f9f8de);
  background: linear-gradient(#a36a1d, #f9f8de);
`;

const LightBack = styled(SectionWrap)`
  background: #d3b989;

  .special-head {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 30px;
    text-align: right;
    color: #555;
    padding-top: 1em;
    padding-right: 1em;
  }
`;

const Separator = styled.div`
  padding: 20px;
`;

const HalfSep = styled.div`
  padding: 2px;
`;

const RevCard = styled(Card)`
  background: #d3b989;
  background-color: rgba(248, 248, 250, 0.5);
  padding: 4px;
  margin: 1rem;
  border: 1px solid rgba(60, 90, 160, 0.25);
  border-radius: 1rem;
`;

const Featured = styled.div`
  border: 2px solid rgba(190, 190, 160, 0.35);
  background-color: rgba(192, 188, 170, 0.15);
  text-align: center;
  border-radius: 16px;
  padding: 10px;

  .head-font {
    font-family: "Roboto", sans-serif;
    text-align: center;
  }

  .blurb-text {
    font-weight: 700;
  }
`;

export default IndexPage;
