import React from "react";
import styled from "styled-components";

import { Container, Row, Col, Image } from "react-bootstrap";
import DSNavBar, { SandCont } from "./header-common";
import DuneLogo from "./dune-logo";
import logoSwitch from "../images/switch-logo-v300.png";

const IntroWrap = styled.div`
  padding-top: 50px;
  padding-bottom: 4em;
  text-align: center;
  color: #f8f8f8;

  .sep1 {
    padding-top: 2em;
    padding-bottom: 0px;
  }
`;

const WidePad = styled.div`
  padding-left: 10em; 
  padding-right: 2em; 

  @media only screen and (max-device-width: 920px) {
    padding-left: 2em; 
    padding-right: 1em; 
  }
`;

/*

const IntroHeader = styled.div`
  padding-top: 2%;
  padding-bottom: 0%;

  h1 {
    margin: 0;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6);
    font-size: 3em;
  }
  @media only screen and (max-device-width: 640px) {
    padding-top: 0%;
    h1 {
      font-size: 2em;
    }
  }
`;

const CenterWrap = styled.div`
  text-align: center;
  vertical-align: middle;
  opacity: 0.99;
`;

const LightBack = styled.div`

  .special-head {
    font-family: "Roboto",sans-serif;
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
    color: #eee;
    padding-top: 1em;
    padding-right: 0em;
  }
`;
*/



const FrontHeader = () => (
  <header>
    <SandCont>
      <DSNavBar />

      <IntroWrap>
        <Container className="row-pad">
          <Row>
            <DuneLogo />
          </Row>
        </Container>       
      </IntroWrap>
        
      <Container >
        <Row className="justify-content-center">
          <Col md={4}>
          <WidePad>
          <a href="https://www.nintendo.com/games/detail/dune-sea-switch/">
            <Image src={logoSwitch} />  </a>
          </WidePad>
          </Col>
        </Row>
      </Container>   
     
    
      {/* <div className="center-text reset-margin">
     
        <iframe 
          title="a966"
            src="https://store.steampowered.com/widget/1103120/" 
            frameBorder="0" width="300" height="190">
        </iframe>
      </div> */}
     
    </SandCont>
    
  </header>
);

export default FrontHeader;
