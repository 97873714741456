import React from "react";
import styled from "styled-components";

import { Container, Row, Col, Image } from "react-bootstrap";
import { FaTwitter, FaReddit, FaDiscord } from "react-icons/fa";
import ItemLink from "../components/item-link";

import omdcLogo from "../images/OMDC.png";
import frolicLogo from "../images/FrolicLogo-200.png";
import indiedbImg from "../images/indiedb.png";

const ServiceBox = styled.div`
  margin: 15px auto 0;
  margin-top: 12px;
  margin-left: 10px;
  padding-left: 20px;
  max-width: 400px;
  text-align: left;
`;

const SocialButtons = styled.div`
  ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    float: left;
    li {
      display: inline-block;
      padding-right: 12px;
      a {
        display: block;
        background-color: #707070;
        text-align: center;
        vertical-align: middle;
        height: 40px;
        width: 40px;
        border-radius: 100%;
        font-size: 24px;
        line-height: 40px;
        color: white;
        outline: none;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        transition: all 0.3s;
      }

      a:hover {
        background-color: #898989;
      }
    }
  }
`;

// TODO: Add discord
const SocialBtnBar = (
  <SocialButtons>
    <ul>
      <ItemLink url="http://twitter.com/duneseagame">
        <FaTwitter />
      </ItemLink>
      <ItemLink url="https://discord.gg/AWHJbys">
        <FaDiscord />
      </ItemLink>
      <ItemLink url="https://www.reddit.com/r/FrolicLabs/">
        <FaReddit />
      </ItemLink>
    </ul>
  </SocialButtons>
);

class FrontFooter extends React.Component {
  //buttonBar(data) {}

  mediaBox(title, email) {
    return (
      <ServiceBox>
        <h5 className="text-head1">{title}</h5>
        <p className="text-copy">
          Email: &nbsp;
          <a
            className="text-email"
            href={email}
            target="_blank"
            rel="noopener noreferrer"
          >
            {email}
          </a>
        </p>
      </ServiceBox>
    );
  }

  render() {
    return (
      <footer>
        <Container>
          <Row className="justify-content-center">
            <h6 className="text-light">
              Nintendo Switch is a trademark of Nintendo
            </h6>
            <hr />
          </Row>
          <hr />
          <Row>
            <Col sm={3} className="center-text">
              <h6 className="text-muted">Made Possible with Support from</h6>
              <img
                className="img-responsive"
                src={omdcLogo}
                alt="omdc"
                width="300"
                height="auto"
              />
            </Col>
            <Col sm={{ span: 3, offset: 1 }}>
              <a
                href="http://froliclabs.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="img-responsive"
                  src={frolicLogo}
                  alt=""
                  width="300"
                  height="auto"
                />
              </a>
            </Col>

            <Col sm={{ span: 4, offset: 1 }}>
              <p className="text-muted">The original Canada Goose Game</p>
              {SocialBtnBar}
              <a href="https://www.indiedb.com/games/dune-sea">
                <Image src={indiedbImg} alt="indieDB" />
              </a>
              <div>
                {this.mediaBox("Media Inquiries", "media@froliclabs.com")}
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={{ span: 4, offset: 4 }} className="center-text">
              <p className="copyright text-copy"> &copy;2019 Frolic Labs</p>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}

export default FrontFooter;
